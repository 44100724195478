.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--color-black);
    color: var(--color-white);

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo {
        display: block;
        margin-right: 1rem;
        color: var(--color-white);
        font-family: var(--font-logo);
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 900;
        text-transform: uppercase;

        .red {
            color: var(--color-red);
        }
    }

    .account {
        display: none;
        color: var(--color-white);
        visibility: hidden;
    }

    .slogan {
        color: var(--color-gray-light);
        font-style: italic;
    }

    .burger {
        display: block;
        width: 30px;
        height: 10px;
        margin-left: 1rem;

        .line {
            display: block;
            width: 30px;
            height: 2px;
            margin-bottom: 4px;
            background-color: var(--color-white);
        }
    }

    .back, .link {
        @media (hover: hover) {
            transition: opacity 0.15s ease-out;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    @media (width >= 760px) {
        .logo {
            font-size: 2rem;
        }

        .account {
            display: block;
            visibility: visible;
        }

        .left, .right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
}
