.container {
    position: relative;
    display: block;
    overflow: hidden;
    font-family: var(--font-text);

    .section {
        position: relative;
        top: -10px;
        left: 5px;
        display: inline-block;
        padding: 3px 6px;
        background-color: var(--color-red);
        color: var(--color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
    }

    .title {
        margin-bottom: 0.5rem;
        color: var(--color-black);
        font-size: 34px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
    }

    .author {
        /* Needed to make it work */
        overflow: hidden;
        color: var(--color-gray);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .image {
        width: 100%;
        height: auto;
        box-shadow: 1px 2px 10px rgb(0 0 0 / 0.5);
    }

    .inner {
        @media (hover: hover) {
            .image {
                transition: filter 0.15s ease-out;
            }

            &:hover {
                .image {
                    filter: brightness(110%);
                }
            }
        }
    }

    .bottom {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        .share {
            padding: 2px 8px;
            border: 2px solid var(--color-gray);
            border-radius: 20px;;
            background-color: var(--color-gray-light);
            color: var(--color-black);
            font-size: 14px;
            line-height: 1;
            opacity: 0;
            transition: opacity 0.15s ease-out;

            &.visible {
                opacity: 1;
            }
        }

        .icon {
            margin-left: 2px;
        }
    }

    &.full {
        background-color: var(--color-black);

        .inner {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .title {
            color: var(--color-white);
        }

        .section {
            left: 0;
        }

        .card {
            padding: 1rem;
            padding-top: 200px;
            background-image: linear-gradient(90deg, rgb(0 0 0 / 0.3), rgb(0 0 0 / 0.9))
        }
    }

    &.small {
        height: 108px;

        .inner {
            display: flex;
        }

        .image {
            display: block;
            width: 144px;
            height: 81px;
            box-shadow: none;
        }

        .card {
            padding: 0.5rem;
        }

        .title {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            font-size: 16px;
            -webkit-line-clamp: 3;
        }

        .section {
            top: -0.5rem;
            left: -0.5rem;
        }

        .cta, .author {
            display: none;
            visibility: hidden;
        }

        .icon {
            margin-left: 0;
        }
    }

    &.normal {
        .image {
            box-shadow: none;
        }

        .card {
            padding: 0 0.5rem 0.5rem;
        }

        .title {
            min-height: 48px;
            font-size: 24px;
        }

        .section {
            left: 0;
        }
    }

    @media (width >= 500px) {
        &.small {
            .image {
                display: block;
                width: 192px;
                height: 108px;
                box-shadow: none;
            }

            .author {
                display: block;
                visibility: visible;
            }
        }
    }

    @media (width >= 768px) {
        &.small {
            .image {
                display: block;
                width: 192px;
                height: 108px;
                box-shadow: none;
            }
        }
    }

    @media (width >= 960px) {
        &.full {
            .card {
                padding-top: 300px;
            }
        }

    }
}
