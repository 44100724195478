.item {
    min-width: 212px;

    + .item {
        margin-top: 0.5rem;
    }
}

.icon {
    position: relative;
    display: block;
    flex-shrink: 0;
}

.icon,
.spacer {
    width: 45px;
    height: 100%;
}

.icon,
.label {
    transition: transform 0.3s ease-out;
}

.label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    margin-right: 0.25em;
    margin-left: 0.25em;
    font-family: Agrandir, helvetica, arial, sans-serif;
    font-size: inherit;
    font-weight: 400;
    line-height: 1;
    transform-origin: center;
}

.labelText {
    flex-shrink: 0;
    margin-right: 0.25em;
    margin-left: 0.25em;
}

.copyLinkButton {
    .label {
        margin-left: 0;
    }
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--color-white);
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-decoration: none;
    transform-origin: 0 50%;
    transition: transform 0.3s ease-out;

    @media (hover: hover) {
        &:hover {
            .icon,
            .label {
                color: var(--color-white);
                transform: scale(1.05);
                transition: transform 0.3s ease-in;
            }
        }
    }

    &:active,
    &:focus {
        .icon,
        .label {
            color: var(--color-white);
            transform: scale(1.05);
            transition: transform 0.3s ease-in;
        }
    }
}

.background {
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    inset: 0;
}
