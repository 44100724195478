.container {
    display: flex;
    max-width: 600px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;



    .button {
        position: relative;
        width: 135px;
        height: 45px;
        border: 1px solid rgb(255 255 255 / 0.2);
        border-radius: 2px;
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;
        background: linear-gradient(180deg, rgb(255 255 255 / 0.1) 0%, rgb(219 219 219 / 0.1) 100%);
        box-shadow: 0 4px 4px 0 rgb(0 0 0 / 0.25);
        font-size: 1px;
        line-height: 1;
        transition: box-shadow 0.15s ease-out, border 0.15s ease-out;

        .logo {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.1rem;
            inset: 0;
        }

        .img, .svg {
            display: block;
            max-width: 80%;
            max-height: 25px;
            margin: 0 auto;
            transition: opacity 0.15s ease-out;
        }

        @media (hover: hover) {
            .img, .svg {
                opacity: 0.8;
            }

            &:hover {
                border: 1px solid rgb(255 255 255 / 0.4);

                .img, .svg {
                    opacity: 1;
                }
            }
        }

        @media (width >= 375px) {
            width: 105px;
            height: 45px;
            margin: 0;
        }

        @media (width >= 768px) {
            width: 190px;
            height: 45px;
            margin: 0;
        }
    }

    @media (width >= 375px) {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (width >= 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.invert {
        .button {
            filter: invert(100%) grayscale(100%);

            &:last-child {
                filter: invert(100%) grayscale(100%);

                .logo {
                    filter: invert(100%);
                }
            }
        }
    }
}
