.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border: 2px solid var(--color-white);
    border-radius: 8px;
    background-color: var(--color-black);
    box-shadow: 1px 2px 8px var(--color-black);
    color: var(--color-white);

    .icon {
        display: block;
        width: 40px;
    }
}
