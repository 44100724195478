:root {
    /* Colors */
    --color-white: #fff;
    --color-black: #000;
    --color-gray-dark: #888;
    --color-gray: #B3B3B3;
    --color-gray-light: #C8C8C8;
    --color-gray-lighter: #F3F3F3;
    --color-background: var(--color-gray-lighter);
    --color-text: var(--color-black);
    --color-red: #F00;

    /* Fonts */
    --font-text: 'Kanit', helvetica, arial, sans-serif;
    --font-title: 'Kanit', helvetica, arial, sans-serif;
    --font-logo: 'Mulish', helvetica, arial, sans-serif;
    --font-legit: 'Agrandir', helvetica, arial, sans-serif;

    /* Sizes */
    --small-screen: 500px;
    --medium-screen: 790px;
    --large-screen: 1000px;

    /* Media */
    --mq-small-screen: 'only screen and (min-width: 500px)';
    --mq-medium-screen: 'only screen and (min-width: 790px)';
    --mq-large-screen: 'only screen and (min-width: 1000px)';
}
