
.container {
    position: relative;
    height: 100%;
    background-color: var(--color-black);

    &.isNative {
        position: static;
    }

    .inner {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
    }

    .overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        inset: 0;
    }

    .iframe {
        width: 100%;
        height: 100%;
        background-image: radial-gradient(closest-side, #333, #000);
    }

    .controls {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 10px;

        button:not(:first-child) {
            margin-left: 6px;
        }
    }

    .loading,
    .playIcon,
    .muteIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        opacity: 0;
        pointer-events: none;
        transform: translate(-50%, -50%);
    }

    .playIcon {
        width: 10%;
        height: 10%;
    }

    .muteIcon {
        width: 20%;
        height: 20%;
    }

    &:not(.withEmbedControls) {
        iframe {
            pointer-events: none;
        }
    }

    &.loading {
        .loading {
            opacity: 1;
        }
    }

    &.paused {
        .playIcon {
            opacity: 1;
        }
    }

    &.muted {
        .muteIcon {
            opacity: 1;
        }
    }
}
