.container {
    padding: 0.5rem;
    border-radius: 16px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-legit);

    @media (width >= 375px) {
        padding: 0.75rem;
    }

    @media (width >= 768px) {
        padding: 1rem;
    }

    .videoContainer {
        position: relative;
    }

    .share {
        position: absolute;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(0 0 0 / 0.9);
        inset: 0;

        &.open {
            background-color: rgb(0 0 0 / 0.5);
        }
    }

    .shareIcon {
        display: inline-block;
        margin-left: 3px;
    }

    .closeIcon {
        display: block;
        width: 15px;
        height: 15px;
    }

    .player {
        position: relative;
        width: 100%;
        max-width: 1000px;
        height: 58vh;
        margin: 0 auto;

        @media (width >= 375px) {
            width: auto;
            height: 58vh;
        }

        @media (width >= 768px) {
            width: auto;
            height: 66vh;
        }

        @media (height >= 1000px) {
            width: auto;
            height: 70vh;
        }
    }

    .title {
        display: block;
        margin: 0.8rem auto 0.6rem;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        @media (width >= 340px) {
            font-size: 16px;
        }

        @media (width >= 768px) {
            margin: 1.36rem auto 1rem;
            font-size: 22px;
        }
    }

    .buttons {
        display: flex;
        max-width: 600px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;

        .button {
            position: relative;
            width: 135px;
            height: 45px;
            border: 1px solid rgb(255 255 255 / 0.2);
            border-radius: 2px;
            margin-bottom: 0.5rem;
            margin-left: 0.5rem;
            background: linear-gradient(180deg, rgb(255 255 255 / 0.1) 0%, rgb(219 219 219 / 0.1) 100%);
            box-shadow: 0 4px 4px 0 rgb(0 0 0 / 0.25);
            font-size: 1px;
            line-height: 1;
            transition: box-shadow 0.15s ease-out, border 0.15s ease-out;

            .logo {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.1rem;
                inset: 0;
            }

            .img, .svg {
                display: block;
                max-width: 80%;
                max-height: 25px;
                margin: 0 auto;
                transition: opacity 0.15s ease-out;
            }

            @media (hover: hover) {
                .img, .svg {
                    opacity: 0.8;
                }

                &:hover {
                    border: 1px solid rgb(255 255 255 / 0.4);

                    .img, .svg {
                        opacity: 1;
                    }
                }
            }

            @media (width >= 375px) {
                width: 105px;
                height: 45px;
                margin: 0;
            }

            @media (width >= 768px) {
                width: 190px;
                height: 45px;
                margin: 0;
            }
        }

        @media (width >= 375px) {
            display: grid;
            grid-gap: 0.5rem;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (width >= 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .text {
        display: inline-block;
        margin-right: 6px;
        font-size: 12px;
        font-weight: 400;

        @media (width >= 768px) {
            font-size: 12px;
        }
    }

    .action {
        position: relative;
        display: inline-block;
        padding: 0;
        padding: 4px 8px 2px;
        border: 0;
        border: 2px solid rgb(255 255 255 / 0.2);
        border-radius: 20px;
        appearance: none;
        background: transparent;
        color: inherit;
        cursor: pointer;
        font-family: inherit;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;

        &.last {
            border: 0;
        }

        @media (width >= 768px) {
            padding: 6px 10px 3px;
            font-size: 14px;
        }
    }

    .collab {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        margin-bottom: 2px;

        .national {
            display: block;
            max-width: 100px;
            margin-bottom: 2px;

            @media (width >= 768px) {
                max-width: 120px;
            }
        }

        @media (width >= 768px) {
            margin-top: 20px;
        }
    }

    .about {
        display: flex;
        margin-bottom: 10px;

        @media (width >= 500px) {
            align-items: center;
            justify-content: space-between;
        }

        @media (hover: hover) {
            .action {
                opacity: 0.8;
                transition: opacity 0.15s ease-out;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
