.container {
    display: none;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    background-color: var(--color-gray-light);
    color: var(--color-black);
    visibility: hidden;

    .items {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item {
        padding-left: 1rem;
        color: var(--color-black);
    }

    @media (width >= 760px) {
        display: flex;
        visibility: visible;
    }
}


