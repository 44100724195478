.container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .inner {
        display: block;
        padding: 0.5rem;
        border-radius: 16px;
        background-color: var(--color-black);
        color: var(--color-white);
        font-family: var(--font-legit);
    }

    .action {
        position: relative;
        display: block;
        padding: 0;
        padding: 4px 8px 2px;
        border: 0;
        border: 2px solid rgb(255 255 255 / 0.2);
        border-radius: 20px;
        margin: 0 0 0.5rem;
        appearance: none;
        background: transparent;
        color: inherit;
        cursor: pointer;
        font-family: inherit;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;

        @media (width >= 768px) {
            padding: 6px 10px 3px;
            font-size: 14px;
        }
    }

    .image {
        display: block;
        max-width: 280px;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
        aspect-ratio: 16 / 9;
    }

    .share {
        display: flex;
    }

    @media (width >= 500px) {
        .image {
            max-width: 320px;
        }
    }

    @media (width >= 768px) {
        padding: 1rem;

        .image {
            max-width: 460px;
            margin-bottom: 0.5rem;
        }
    }
}
