@import './commons/reset.css';
@import './commons/variables.css';

@font-face {
    font-display: swap;
    font-family: kanit;
    font-weight: 400;
    src: url('./fonts/kanit/Kanit-Regular.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-Regular.woff') format('woff');
}

body {
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-text);
}
