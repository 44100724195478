.container {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    inset: 0;
    pointer-events: none;

    .closeBtn {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        display: none;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 2rem;
        background-color: var(--color-black);
        color: var(--color-white);
        visibility: hidden;

        @media (width >= 375px) {
            top: 0.75rem;
            right: 0.75rem;
        }

        @media (width >= 500px) {
            top: 1rem;
            right: 1rem;
        }
    }

    .wrapper {
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        display: flex;
        overflow: auto;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 0;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
    }

    .inner {
        opacity: 0;

        @media (width >= 960px) {
            width: 100%;
            height: 100%;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .lightBox {
        position: absolute;
        z-index: 1;
        backdrop-filter: blur(4px);
        background-color: rgb(0 0 0 / 0.5);
        inset: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;
    }

    .content {
        display: flex;
        width: 100%;
        min-height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .innerContent {
            background-color: var(--color-white);
            color: var(--color-black);
        }

        .video {
            width: 100%;
            max-width: 768px;
        }

        @media (height >= 860px) {
            padding: 1rem 0;
        }
    }

    &.visible {
        pointer-events: all;

        .closeBtn {
            z-index: 99999;
            display: flex;
            pointer-events: all;
            visibility: visible;
        }

        .lightBox {
            opacity: 1;
            pointer-events: all;
            transition: opacity 500ms ease-in-out
        }

        .inner {
            opacity: 1;
            transition: opacity 500ms ease-in-out
        }
    }


}
