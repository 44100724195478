.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-legit);

    .link {
        opacity: 0.8;
    }

    .back, .link {
        padding: 9px 0 7px;
        font-weight: 700;
        line-height: 1;

        @media (hover: hover) {
            transition: opacity 0.15s ease-out;

            &:hover {
                opacity: 0.8;
            }
        }

        @media (width >= 760px) {
            font-size: 1rem;
        }
    }
}
