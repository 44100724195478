.container {
    max-width: 1200px;
    padding: 1rem;
    margin: 0 auto;
    font-family: var(--font-text);

    .title {
        display: block;
        margin: 0 auto 1rem;
        font-family: var(--font-logo);
        font-size: 1.3rem;
        font-style: italic;
        font-weight: 900;
        line-height: 1.1;
        text-align: center;
        text-transform: uppercase;
    }

    .top {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .large, .small {
        width: 100%;
        margin-bottom: 1rem;
    }

    .end {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 1rem 0.5rem;
    }

    .button {
        padding: 1rem;
        background-color: var(--color-black);
        color: var(--color-white);
        font-weight: 500;
    }

    @media (width >= 768px) {
        .title {
            font-size: 2rem;
        }

        .top {
            flex-direction: row;
            margin-bottom: 1rem;

            .large {
                width: 50%;
                padding-right: 0.5rem;
                margin-bottom: 0;
            }

            .small {
                width: 50%;
                padding-left: 0.5rem;
                margin-bottom: 0;
            }
        }
    }

    @media (width >= 960px) {
        .title {
            font-size: 2.4rem;
        }

        .top {
            .large {
                width: 66.6%;
            }

            .small {
                width: 33.3%;
            }
        }
    }
}
