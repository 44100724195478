.container {
    position: relative;

    .inner {
        transition: opacity 0.2s ease-out;
    }

    .playVideo {
        position: fixed;
        bottom: 0.5rem;
        left: 0.5rem;
        width: 60px;
        height: 40px;
    }
}
