.container {
    display: grid;
    margin-bottom: 1rem;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

    .article {
        height: 100%;
        background-color: var(--color-white);
    }

    @media (width >= 500px) {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}
