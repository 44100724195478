.container {
    max-width: 1200px;
    padding: 1rem 1rem 4rem;
    margin: 0 auto;
    font-family: var(--font-legit);

    .title {
        display: block;
        max-width: 768px;
        margin: 2rem auto 3rem;
        font-family: inherit;
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 1.1;
        text-align: center;
        text-transform: none;
    }

    .content {
        max-width: 768px;
        margin: 0 auto;
    }

    .subtitle {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.1;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    .paragraph {
        margin-bottom: 1rem;
        font-size: 16px;
        line-height: 1.35;
    }

    .lastTitle {
        margin-top: 3rem;
        margin-bottom: 3.2rem;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.1;
    }

    .subscribe {
        display: grid;
        width: 100%;
        max-width: none;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 3rem;
        margin-bottom: 0;
        grid-gap: 0.5rem;
        grid-template-columns: 1fr 1fr;
    }

    .button {
        width: 100%;
        border: 1px solid transparent;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
        box-shadow: none;
    }

    @media (width >= 375px) {
        .subscribe {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .button {
            width: 100%;
        }
    }

    @media (width >= 400px) {
        .button {
            width: 100%;
        }
    }

    @media (width >= 768px) {
        .title {
            font-size: 2rem;
        }

        .paragraph {
            font-size: 18px;
        }

        .subscribe {
            display: grid;
            margin-bottom: 3rem;
        }

        .button {
            width: 100%;
        }
    }

    @media (width >= 960px) {
        .title {
            font-size: 2.4rem;
        }
    }
}
