.container {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: var(--color-black);
    color: var(--color-gray-light);

    .items {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item {
        color: var(--color-gray-light);
    }
}
