.container {
    position: relative;
    display: inline-block;
    padding: 0;
    border: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font-family: var(--font-text);
}
