@font-face {
    font-display: swap;
    font-family: Kanit;
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/kanit/Kanit-Regular.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Kanit;
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/kanit/Kanit-MediumItalic.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-MediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/kanit/Kanit-Medium.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-Medium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Kanit;
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/kanit/Kanit-MediumItalic.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-MediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Kanit;
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/kanit/Kanit-Bold.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Kanit;
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/kanit/Kanit-ExtraBold.woff2') format('woff2'),
        url('./fonts/kanit/Kanit-ExtraBold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Mulish;
    font-style: italic;
    font-weight: 800;
    src: url('./fonts/mulish/Mulish-ExtraboldItalic.woff2') format('woff2'),
        url('./fonts/mulish/Mulish-ExtraboldItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Mulish;
    font-style: italic;
    font-weight: 900;
    src: url('./fonts/mulish/Mulish-BlackItalic.woff2') format('woff2'),
        url('./fonts/mulish/Mulish-BlackItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/agrandir/Agrandir-Regular.woff2') format('woff2'),
        url('./fonts/agrandir/Agrandir-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/agrandir/Agrandir-Bold.woff2') format('woff2'),
        url('./fonts/agrandir/Agrandir-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/agrandir/Agrandir-BoldItalic.woff2') format('woff2'),
        url('./fonts/agrandir/Agrandir-BoldItalic.woff') format('woff');
}
