
.container {
    position: relative;
    display: flex;
    min-height: 33vh;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;

    .title {
        padding-bottom: 1rem;
        font-size:32px;
    }

    .description, .actions {
        padding-bottom: 1rem;
    }
}
